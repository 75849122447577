.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid transparent;
    border-radius: 0;
}
.card .card-subtitle {
    font-weight: 300;
    color: #8898aa;
}
/* .table-product.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f3f8fa!important
} */
.table-product td{
    border-top: 0px solid #dee2e6 !important;
    color: #728299!important;
}