/* <<<<<<< Updated upstream
#sailorTableArea {
	max-height: 300px;
	overflow-x: auto;
	overflow-y: auto;
}
#sailorTable {
	white-space: normal;
}
tbody {
	display: block;
	height: 200px;
	overflow: auto;
}
thead,
tbody tr {
	display: table;
	width: 100%;
	table-layout: fixed;
}
=======
.data-table-extensions-filter {
    border: 1px solid lightgray;
    border-radius: 50px;
    padding: 3px 5px;
    width: 70%;
  }
>>>>>>> Stashed changes */
/* body {
	background: #f5f5f5;
	margin-top: 20px;
} */

/* ===== Career ===== */
.search__form {
	/* background-color: #4e63d7; */
	box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	padding: 0 16px;
}

.search__form .form-control {
	background-color: rgba(255, 255, 255, 0.2);
	border: 0;
	padding: 12px 15px;
	color: #0d6efd;
	box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
}

.search__form .form-control::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #0d6efd;
}

.search__form .form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: #0d6efd;
}

.search__form .form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: #0d6efd;
}

.search__form .form-control:-moz-placeholder {
	/* Firefox 18- */
	color: #0d6efd;
}

.search__form .select__search {
	background-color: rgba(255, 255, 255, 0.2);
	border: 0;
	padding: 12px 15px;
	color: #0d6efd;
	width: 100%;
	border-radius: 5px;
	text-align: left;
	height: auto;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	background-image: none;
}

.search__form .select__search:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.search__form .select-container {
	position: relative;
}

.search__form .select-container:before {
	position: absolute;
	right: 15px;
	top: calc(50% - 14px);
	font-size: 18px;
	color: #ffffff;
	content: '\F2F9';
	font-family: 'Material-Design-Iconic-Font';
}

.filter-result .exhibit-box {
	-webkit-box-shadow: 0 0 35px 0 rgba(130, 130, 130, 0.2);
	box-shadow: 0 0 35px 0 rgba(130, 130, 130, 0.2);
	border-radius: 10px;
	padding: 10px 35px;
}

ul {
	list-style: none;
}


.exhibit-box .img-holder {
	height: 65px;
	width: 65px;
	border: 2px solid black;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 22px;
	font-weight: 700;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 65px;
}
