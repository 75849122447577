.p-xl {
	padding: 40px;
}

.lazur-bg {
	background-color: #23c6c8;
	color: #ffffff;
}

.red-bg {
	background-color: #ed5565;
	color: #ffffff;
}

.navy-bg {
	background-color: #1ab394;
	color: #ffffff;
}

.yellow-bg {
	background-color: #f8ac59;
	color: #ffffff;
}

.widget {
	border-radius: 5px;
	padding: 15px 20px;
	margin-bottom: 10px;
	margin-top: 10px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
}

.widget h2,
.widget h3 {
	margin-top: 5px;
	margin-bottom: 0;
	border-bottom: 1px dotted white;
}

.m-t-md {
	margin-top: 20px;
}
